var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:"},[_c('div',{staticClass:"tw-w-full",staticStyle:{"background":"#FDFFFC","padding":"30px","margin-bottom":"40px"}},[_c('div',{staticClass:"tw-flex tw-w-full lg: tw-mb-10"},[_c('p',{staticClass:"table-head-txt"},[_vm._v("Real Time Search Data")]),_c('v-spacer'),_c('div',{staticClass:"tw-ml-32",staticStyle:{"border":"1px solid #E1E3E0","border-radius":"10px","max-width":"170px","min-width":"170px"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"170px","min-width":"170px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"p-0",attrs:{"placeholder":"24-11-2022","solo":"","flat":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icons',{staticClass:"tw-mr-5",attrs:{"name":"analytics-calender-icon","color":"#004AAD"}})]},proxy:true}],null,true)},on))]}}])},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","scrollable":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":'#004aad'}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":'#004aad'}},[_vm._v("OK ")])],1)],1)],1)],1),_c('div',[_c('apexchart',{attrs:{"type":"line","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1)]),_c('div',{staticClass:"tw-flex tw-w-full",staticStyle:{"background":"#FDFFFC","padding":"20px","border-radius":"10px"}},[_c('p',{staticClass:"table-head-txt tw-mt-4"},[_vm._v("Search Queries")]),_c('v-spacer'),_c('ValidationProvider',{attrs:{"name":"Trip Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5",class:classes,staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"p-0",attrs:{"placeholder":"24-11-2022","solo":"","flat":"","hide-details":"","readonly":"","value":_vm.startDate},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icons',{staticClass:"tw-mr-5",attrs:{"name":"analytics-calender-icon","color":"#004AAD"}})]},proxy:true}],null,true)},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","color":'#004aad'},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer')],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Trip Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5",class:classes,staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"p-0",attrs:{"placeholder":"24-11-2022","solo":"","flat":"","hide-details":"","readonly":"","value":_vm.endDate},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('Icons',{staticClass:"tw-mr-5",attrs:{"name":"analytics-calender-icon","color":"#004AAD"}})]},proxy:true}],null,true)},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","scrollable":"","color":'#004aad'},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer')],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('v-btn',{staticClass:"search-btn tw-mr-5 ",attrs:{"loading":_vm.loading,"color":'#004aad'},on:{"click":_vm.getSearchQueryByDateRange}},[_vm._v("Search")]),_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-center tw-items-center"},[_c('div',{staticClass:"mr-2",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search","solo":"","flat":"","hide-details":"","color":"#FDFFFC"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":'#004aad'}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":'#004aad'}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":0,"single-select":false,"height":"available","hide-default-footer":true,"search":_vm.search,"header-props":{ 'style': 'background: #004aad;' }},scopedSlots:_vm._u([{key:"header.departure",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v(" Departure ")])]},proxy:true},{key:"item.departure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.departure.state + " -"+ item.departure.city)+" ")]}},{key:"header.destination",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Destination")])]},proxy:true},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style text-capitalize"},[_vm._v(_vm._s(item.destination.state + " -" + item.destination.city))])]}},{key:"header.averageTripAmount",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v(" Average Trip Amount ")])]},proxy:true},{key:"item.averageTripAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v("₦"+_vm._s(item.averageSuggestedTripAmount))])]}},{key:"header.count",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Count")])]},proxy:true},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.totalCounts))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }