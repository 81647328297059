<script>
import Icons from "@/components/reuseables/Icons.vue";
import {getAllSearchQuery, getSearchQueryByDate} from "@/services/api/APIService";

export default {
  name: "AnalyticsDashboard",
  components: {
    Icons,
  },

  data(){
    return{
      search: "",
      startDate: null,
      endDate: null,
      selectedDate: '',

      dateMenu: null,
      fromDateMenu: false,
      loading: false,
      headers: [
        { text: "", value: "departure" },
        { text: "", value: "destination" },
        { text: "", value: "averageTripAmount" },
        { text: "", value: "count" },
      ],
      series: [{
        name: "Desktops",
        data: [0, 10, 20, 30, 40,50,60,70,80,90]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#FDFFFC', '#FDFFFC'],
          },
        },
        xaxis: {
          categories: ['00:00', '02:00', '04:00', '06:00', '08:00',
            '10:00', '12:00', '14:00', '16:00','18:00','20:00','22:00'],
        }
      },
      minDate: new Date().toISOString().substr(0, 10),

    queryBody:{
        pageNumber: 0,
        pageSize: 10,
    },
      items:[]


    }
  },
  methods:{
    async getSearchQueryByDateRange(){
      this.loading = true
      let data = {}
      data.startSearchDate = this.startDate
      data.endSearchDate = this.endDate
      await getSearchQueryByDate(data).then(res => {
        this.loading = false
        this.items = res.data

      }).catch(err =>{
        this.$displaySnackbar({
          message:err.response.data.details[0],
          success: false
        })
      })
    },


    async getAllSearchQuery(){
      this.loading = true
      let data = {}
      data.pageNumber = this.queryBody.pageNumber
      data.pageSize = this.queryBody.pageSize
      await getAllSearchQuery(data).then(res => {
      this.loading = false
        this.items = res.data
      }).catch(err => {
        this.$displaySnackbar({
          message:err.response.data.details[0],
          success: false
        })
      }).finally(() => this.loading = false)
    }
  },
  mounted() {
    this.getAllSearchQuery();
  },

}
</script>

<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">

  <div class="tw-w-full" style="background: #FDFFFC; padding: 30px; margin-bottom: 40px;">


<div class="tw-flex tw-w-full lg: tw-mb-10">
  <p class="table-head-txt"  >Real Time Search Data</p>

<v-spacer></v-spacer>

  <div class="tw-ml-32" style=" border: 1px solid #E1E3E0;
  border-radius:10px;  max-width: 170px; min-width: 170px">
  <v-menu
      ref="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="170px"
      min-width="170px"

  >
    <template v-slot:activator="{ on }">
      <v-text-field
          v-on="on"
          placeholder="24-11-2022"
          solo
          flat
          hide-details
          readonly
          class="p-0"

      >
        <template #prepend-inner>
          <Icons  class="tw-mr-5" name="analytics-calender-icon" color="#004AAD">
          </Icons>
        </template></v-text-field
      >
    </template>
    <v-date-picker
        locale="en-in"
        no-title

        scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          :color="'#004aad'">
        Cancel
      </v-btn>
      <v-btn text :color="'#004aad'">OK
      </v-btn>
    </v-date-picker>
  </v-menu>
  </div>
</div>



    <div><apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart></div>
  </div>



<div class="tw-flex tw-w-full" style="background: #FDFFFC; padding: 20px; border-radius:10px">
  <p class="table-head-txt tw-mt-4">Search Queries</p>
  <v-spacer></v-spacer>
  <ValidationProvider
      name="Trip Date"
      rules="required"
      v-slot="{ classes, errors }"
  >

    <div class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5" :class="classes"  style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                ">

      <v-menu
          ref="fromDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"

      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-on="on"
              placeholder="24-11-2022"
              solo
              flat
              hide-details
              readonly
              class="p-0"
              :value="startDate"
          >
            <template #prepend-inner>
              <Icons  class="tw-mr-5" name="analytics-calender-icon" color="#004AAD">
              </Icons>
            </template></v-text-field
          >
        </template>
        <v-date-picker
            locale="en-in"
            no-title
            v-model="startDate"
            :color="'#004aad'"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-menu>
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
  <ValidationProvider
      name="Trip Date"
      v-slot="{ classes, errors }"
  >

    <div class="tw-mb-5 lg:tw-mb-0 tw-mr-0 lg:tw-mr-5" :class="classes"       style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                ">
      <v-menu
          ref="fromDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"

      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-on="on"
              placeholder="24-11-2022"
              solo
              flat
              hide-details
              readonly
              class="p-0"
              :value="endDate"
          >
            <template #prepend-inner>
              <Icons  class="tw-mr-5" name="analytics-calender-icon" color="#004AAD">
              </Icons>
            </template></v-text-field
          >
        </template>
        <v-date-picker
            locale="en-in"
            no-title
            scrollable
            v-model="endDate"
            :color="'#004aad'"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-menu>
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>

  <v-btn  class="search-btn tw-mr-5 " :loading="loading"
          :color="'#004aad'" @click="getSearchQueryByDateRange">Search</v-btn>

  <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
    <div
        class="mr-2"
        style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
    >
      <v-text-field
          placeholder="Search"
          class="p-0"
          solo
          flat
          hide-details
          color="#FDFFFC"
          v-model="search"
      >
        <template #prepend>
          <v-icon :color="'#004aad'" class="ml-5">
            mdi-magnify
          </v-icon>
        </template>
        <template #append>
          <v-icon :color="'#004aad'"> mdi-filter-variant </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</div>

  <v-data-table
      :headers="headers"
      :items="items"
      :mobile-breakpoint="0"
      :single-select="false"
      height="available"
      :hide-default-footer="true"
      class="mt-4 mx-1 table"
      :search="search"

      :header-props="{ 'style': 'background: #004aad;' }"
  >

<!--    <template #header.state>-->
<!--      <span class="status"> State </span>-->
<!--    </template>-->
<!--    <template v-slot:[`item.state`]="{ item }">-->
<!--      <a :href="`//`" class="links">{{ item.state }}</a>-->
<!--    </template>-->
    <template #header.departure>
      <span class="status"> Departure </span>
    </template>
    <template v-slot:[`item.departure`]="{ item }">
      {{ item.departure.state + " -"+ item.departure.city }}
    </template>
    <template #header.destination>
      <span class="status">Destination</span>
    </template>
    <template v-slot:[`item.destination`]="{ item }">
              <span class="td-style text-capitalize"
              >{{item.destination.state + " -" + item.destination.city}}</span
              >
    </template>
    <template #header.averageTripAmount>
      <span class="status"> Average Trip Amount </span>
    </template>
    <template v-slot:[`item.averageTripAmount`]="{item }">
      <span class="td-style">&#x20A6;{{item.averageSuggestedTripAmount}}</span>
    </template>

    <template #header.count>
      <span class="status">Count</span>
    </template>
    <template v-slot:[`item.count`]="{item }">
              <span class="td-style">{{item.totalCounts}}</span>
    </template>

  </v-data-table>


</div>
</template>

<style scoped lang="scss">
.table-head-txt{
  font-family: Inter,sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #004AAD;
}
.links{
  font-family: Inter,sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #004aad;
  text-transform: none;
}
.v-data-table-header {
  background-color: black !important;
}


.search-btn {
  width: 100px;
  height: auto;
  border-radius: 10px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize !important;
  letter-spacing: 0;
  color: #FFFFFF;
}



</style>